import Vue from 'vue';
import Vuex from 'vuex';
import jwtDecode from 'jwt-decode';

Vue.use(Vuex);

import Utils from '~/common/utils/store';

function initialState() {
  return {
    _id: '',
    email: '',
    name: '',
    workspaces: [],
    organizations: [],
    currentWorkspace: null,
    currentOrganization: null,
    token: null,
    tokenIdleTimeout: 0,
    refreshToken: null,
    websocketToken: null,
    permissions: [],
    roles: [],
  };
}

// initial state
const state = () => {
  return {
    user: initialState(),
    supportUser: null,
    forceDefaultRoute: null,
    previousConnectedEmail: null,
    refreshTokenPromise: null
  };
}

// getters are functions
const getters = {
  isLogged: state => state.user.token != null,
  permissions: state => state.user.permissions,
  nameFromEmail: state => {
    return state.user.email ? state.user.email.split('@').shift() : state.user.name;
  },
  getToken: state => state.user.token,
  getTokenIdleTimeout: state => state.user.tokenIdleTimeout,
  getUser: state => state.user,
  getUID: state => state.user._id,
  //Check user's permissions or role. usage: checkPerm("admin")
  checkPerm: state => type => {
    return state.user.permissions.indexOf(type) != -1;
  },
  isLoggedAsSupport: state => {
    return state.supportUser !== null;
  },
  getDefaultRoute: state => {
    if (!state.user || !state.user.token) {
      return { name: "login" };
    }
    else if (state.forceDefaultRoute) {
      return state.forceDefaultRoute;
    }
    else if (state.user.permissions.indexOf('admin:all') !== -1) {
      return { name: "admin" };
    } else if (state.user.permissions.indexOf('editor:all') !== -1) {
      return { name: "client" };
    } else {
      return { name: "login" };
    }
  },
  getCurrentOrganizationId: state => {
    return state.user && state.user.currentOrganization ? state.user.currentOrganization : null;
  },
  getOrganizations: state => {
    return state.user && state.user.organizations ? state.user.organizations : null;
  },
  getCurrentWorkspaceId: state => {
    return state.user && state.user.currentWorkspace ? state.user.currentWorkspace : null;
  },
  getWorkspaces: state => {
    return state.user && state.user.workspaces ? state.user.workspaces : null;
  },
  currentOrganization: state => {
    if (state.user && state.user.currentOrganization && state.user.organizations) {
      const organization = state.user.organizations.find(
        (organization) => organization._id === state.user.currentOrganization
      );
      if (organization) {
        return organization;
      }
    }
    return null;
  },
  currentWorkspace: state => {
    if (state.user && state.user.currentWorkspace && state.user.workspaces) {
      const workspace = state.user.workspaces.find(
        (workspace) => workspace._id === state.user.currentWorkspace
      );
      if (workspace) {
        return workspace;
      }
    }
    return null;
  },
  isB2cOrganization: (state, getters) => {
    return getters.currentOrganization && getters.currentOrganization.marketType === 'B2C';
  },
  isB2bOrganization: (state, getters) => {
    return getters.currentOrganization && getters.currentOrganization.marketType === 'B2B';
  },

  getPreviousConnectedEmail: state => state.previousConnectedEmail,
};

const mutations = {
  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one field of state.user
   */
  UPDATE_FIELD: (state, { key, value }) => {
    Utils.setProperty(key, state.user, value);
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one field of state
   */
  UPDATE_ROOT_FIELD: (state, { key, value }) => {
    Utils.setProperty(key, state, value);
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   *
   * reset state.user
   */
  RESET_USER: state => {
    state.user = Object.assign({}, initialState());
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   *
   * reset complete state
   */
  RESET_STATE: state => {
    state.user = Object.assign({}, initialState());
    state.supportUser = null;
  },

  /**
   * read permissions from token and update state
   */
  UPDATE_PERMISSIONS: state => {
    let tokenData = jwtDecode(state.user.token);
    state.user.permissions = tokenData.permissions;
    state.user.sessionId = tokenData.sessionId;
  }
};

const actions = {
  API_SUCCESS: function (context, payload) {
    if (payload.display === true) {
      if (payload.tr) {
        payload.message = this.app.i18n.t(payload.tr, payload.trParams);
      }
      this._vm.$message(payload);
    }
  },
  API_ERROR: function (context, payload) {
    console.error('API_ERROR payload', payload);
    if (payload !== undefined) {

      if (typeof payload === 'object') {
        if (payload.printerError) {
          const printerError = payload.printerError;
          let msg = printerError.message;
          if (printerError.details) {
            for (const detail of printerError.details) {
              if (detail.reference && detail.message) {
                msg += ", " + detail.reference + ": " + detail.message;
              } else if (detail.message) {
                msg += ", " + detail.message;
              }
            }
          }
          payload.message = msg;
        }
        if (payload.tr) {
          payload.message = this.app.i18n.t(payload.tr, payload.trParams);
        }
        if (!payload.message) {
          payload.message = payload.error;
        }

        // don't display token error
        if (payload.message &&
          (
            payload.message.indexOf('UnauthorizedError') === 0 ||
            payload.message.indexOf('Invalid token specified') === 0
          )) {
          return;
        }

        if (payload.type) {
          switch (payload.type) {
            case 'alert':
              this._vm.$alert(
                payload.message,
                '',
                {
                  confirmButtonText: this.app.i18n.t('public.alert.ok')
                }
              );
              return;
          }
        }
      }

      this._vm.$messageError(payload);
    }
  },

  API_CHECK_EMAIL_LOGIN: function (context, payload) {
    return this.$axios
      .post('/api/v1/users/checkEmailLogin', payload)
      .then(r => r.data)
      .then(
        json => {
          return json;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        });
  },

  API_CHECK_LOGIN_BY_NICKNAME_CODE: function (context, payload) {
    return this.$axios
      .post('/api/v1/users/checkLoginByNicknameCode', payload)
      .then(r => r.data)
      .then(
        json => {
          return json;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        });
  },

  // send default local strategy login (email/password or email/code)
  API_LOGIN: function (context, payload) {
    return this.$axios
      .post('/api/v1/users/login/' + payload.authStrategy, payload)
      .then(r => r.data)
      .then(
        json => {
          const { token } = json;
          return this.dispatch('api/API_LOAD_LOGGED_USER', { token });
        },
        err => {
          if (err.response && err.response.data && err.response.data.error) {
            switch (err.response.data.error) {
              case 'validatePasswordError':
                if (payload.authStrategy === 'login_code') {
                  this.dispatch('api/API_ERROR', "This code is not valid or has expired.");
                } else if (payload.authStrategy === 'login_password') {
                  this.dispatch('api/API_ERROR', "This password is not valid.");
                } else {
                  this.dispatch('api/API_ERROR', "This credentials is not valid.");
                }
                break;

              case 'collaborationUserNotFound':
                this.dispatch('api/API_ERROR', { tr: "serverResponse.login.collaborationUserNotFound" });
                break;

              default:
                this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
            }
          } else {
            this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          }
          return false;
        }
      );
  },

  API_LOAD_LOGGED_USER: function (context, { token, force }) {
    return this.$axios
      .post('/api/v1/users/userFromLoginToken', { token, force, storageIsDisabled: this.$mzLocalStorage.storageIsDisabled })
      .then(r => r.data)
      .then(
        json => {
          const { user, routeAfterLogin, keepLogin, supportLogin } = json;

          if (supportLogin) {
            this.commit('api/UPDATE_ROOT_FIELD', { key: 'supportUser', value: { ...context.state.user } });

            // reset another state for login as support with cleaned store values
            const _excludeStoreToReset = ['api', 'app', 'i18n'];
            for (const _module in context.rootState) {
              if (_excludeStoreToReset.indexOf(_module) === -1) {
                this.commit(_module + '/RESET_STATE');
              }
            }
          } else {
            this.commit('api/UPDATE_ROOT_FIELD', { key: 'supportUser', value: null });

            if (keepLogin) {
              this.$mzLocalStorage.setItem('mzkeeplogin', true);
            } else {
              this.$mzLocalStorage.removeItem('mzkeeplogin');
            }

            if (this.$mzLocalStorage.storageIsDisabled) {
              this._vm.$alert(
                this.app.i18n.t("public.layout.localStorage.error.message"),
                this.app.i18n.t("public.layout.localStorage.error.title"),
                {
                  dangerouslyUseHTMLString: true,
                });
            }
          }

          this.$mzLocalStorage.setItem('mztoken', user.token);
          this.$mzLocalStorage.setItem('mzrefreshtoken', user.refreshToken);


          this.commit('api/UPDATE_FIELD', { key: 'refreshToken', value: user.refreshToken });
          this.commit('api/UPDATE_FIELD', { key: 'token', value: user.token });
          this.commit('api/UPDATE_FIELD', { key: 'tokenIdleTimeout', value: user.tokenIdleTimeout });
          this.commit('api/UPDATE_FIELD', { key: 'websocketToken', value: user.websocketToken });
          this.commit('api/UPDATE_FIELD', { key: 'email', value: user.email });
          this.commit('api/UPDATE_FIELD', { key: 'workspaces', value: user.workspaces });
          this.commit('api/UPDATE_FIELD', { key: 'organizations', value: user.organizations });
          this.commit('api/UPDATE_FIELD', { key: 'currentWorkspace', value: user.currentWorkspace });
          this.commit('api/UPDATE_FIELD', { key: 'currentOrganization', value: user.currentOrganization });
          this.commit('api/UPDATE_FIELD', { key: 'name', value: user.name });
          this.commit('api/UPDATE_FIELD', { key: 'roles', value: user.roles });
          this.commit('api/UPDATE_FIELD', { key: '_id', value: user._id });
          this.commit('api/UPDATE_PERMISSIONS');

          this.$axios.setToken(user.token, 'Token');

          if (routeAfterLogin) {
            this.commit('api/UPDATE_ROOT_FIELD', { key: 'forceDefaultRoute', value: routeAfterLogin });
          }

          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'user is logged' });

          return true;
        },
        err => {
          if (err.response && err.response.data && err.response.data.canForce && !force) {
            return new Promise((resolve, reject) => {
              this._vm.$confirm(
                this.app.i18n.t(err.response.data.tr, err.response.data.trParams),
                this.app.i18n.t('public.login.alreadyLoggedUser.title'),
                {
                  confirmButtonText: this.app.i18n.t('public.login.alreadyLoggedUser.confirm'),
                  cancelButtonText: this.app.i18n.t('public.login.alreadyLoggedUser.cancel'),
                  dangerouslyUseHTMLString: true,
                }).then(() => {
                  this.dispatch('api/API_LOAD_LOGGED_USER', { token, force: true }).then(success => {
                    resolve(success);
                  });
                }).catch(() => {
                  resolve(false);
                })
            });
          } else {
            console.log('login error', err);
            this.dispatch('api/API_ERROR', err.response ? err.response.data : err);

            return false;
          }
        }
      );


  },


  /**
  * check valid invitation code and get user informations
  * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
  * @return Promise
  */
  API_CHECK_INVIT_TOKEN: async function (context, { token }) {
    return this.$axios
      .get('/api/v1/users/checkInvitationToken/' + token)
      .then(r => r.data)
      .then(
        user => {
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'invit token is valid' });
          return user;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  API_RESEND_AUTH_CODE: function (context, payload) {
    return this.$axios
      .post('/api/v1/users/resendAuthCode', payload)
      .then(r => r.data)
      .then(
        json => {
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'A new authentication code has been sent.', display: true });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        });
  },

  API_CHANGE_WORKSPACE: function (context, payload) {
    return this.$axios
      .post('/api/v1/users/changeWorkspace', payload)
      .then(r => r.data)
      .then(
        json => {
          const { user } = json;

          this.$mzLocalStorage.setItem('mztoken', user.token);

          if (user.refreshToken) {
            this.$mzLocalStorage.setItem('mzrefreshtoken', user.refreshToken);
            this.commit('api/UPDATE_FIELD', { key: 'refreshToken', value: user.refreshToken });
          }

          this.commit('api/UPDATE_FIELD', { key: 'token', value: user.token });
          this.commit('api/UPDATE_FIELD', { key: 'tokenIdleTimeout', value: user.tokenIdleTimeout });
          this.commit('api/UPDATE_FIELD', { key: 'websocketToken', value: user.websocketToken });
          this.commit('api/UPDATE_FIELD', { key: 'workspaces', value: user.workspaces });
          this.commit('api/UPDATE_FIELD', { key: 'organizations', value: user.organizations });
          this.commit('api/UPDATE_FIELD', { key: 'currentWorkspace', value: user.currentWorkspace });
          this.commit('api/UPDATE_FIELD', { key: 'currentOrganization', value: user.currentOrganization });
          this.commit('api/UPDATE_FIELD', { key: 'roles', value: user.roles });
          this.commit('api/UPDATE_FIELD', { key: '_id', value: user._id });

          this.$axios.setToken(user.token, 'Token');

          this.commit('api/UPDATE_PERMISSIONS');

          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'change workspace success' });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  API_CHANGE_USERDATA: function (context, payload) {
    return this.$axios
      .put('/api/v1/users/update', payload)
      .then(r => r.data)
      .then(
        json => {
          const { user } = json;

          this.commit('api/UPDATE_FIELD', { key: 'name', value: user.name });

          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'change name success' });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  API_LOGOUT_SUPPORT: function (context, payload) {
    return this.$axios
      .post('/api/v1/users/logout', { userId: context.state.user._id })
      .then(r => r.data)
      .then(
        json => {
          let user = { ...context.state.supportUser };

          this.commit('api/UPDATE_ROOT_FIELD', { key: 'supportUser', value: null });

          // reset another state for login as admin with cleaned store values
          const _excludeStoreToReset = ['api', 'app', 'i18n'];
          for (const _module in context.rootState) {
            if (_excludeStoreToReset.indexOf(_module) === -1) {
              this.commit(_module + '/RESET_STATE');
            }
          }

          this.$mzLocalStorage.setItem('mztoken', user.token);
          this.$mzLocalStorage.setItem('mzrefreshtoken', user.refreshToken);

          this.commit('api/UPDATE_FIELD', { key: 'refreshToken', value: user.refreshToken });
          this.commit('api/UPDATE_FIELD', { key: 'token', value: user.token });
          this.commit('api/UPDATE_FIELD', { key: 'tokenIdleTimeout', value: user.tokenIdleTimeout });
          this.commit('api/UPDATE_FIELD', { key: 'websocketToken', value: user.websocketToken });
          this.commit('api/UPDATE_FIELD', { key: 'email', value: user.email });
          this.commit('api/UPDATE_FIELD', { key: 'workspaces', value: user.workspaces });
          this.commit('api/UPDATE_FIELD', { key: 'organizations', value: user.organizations });
          this.commit('api/UPDATE_FIELD', { key: 'currentWorkspace', value: user.currentWorkspace });
          this.commit('api/UPDATE_FIELD', { key: 'currentOrganization', value: user.currentOrganization });
          this.commit('api/UPDATE_FIELD', { key: 'name', value: user.name });
          this.commit('api/UPDATE_FIELD', { key: 'roles', value: user.roles });
          this.commit('api/UPDATE_FIELD', { key: '_id', value: user._id });
          this.commit('api/UPDATE_PERMISSIONS');

          this.$axios.setToken(user.token, 'Token');

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  API_REGISTER: function (context, payload) {
    return this.$axios
      .post('/api/v1/users', { user: payload.user })
      .then(r => r.data)
      .then(
        json => {
          const { user, routeAfterLogin } = json;

          if (user) {
            this.$mzLocalStorage.setItem('mztoken', user.token);
            this.$mzLocalStorage.setItem('mzrefreshtoken', user.refreshToken);

            this.commit('api/UPDATE_FIELD', { key: 'refreshToken', value: user.refreshToken });
            this.commit('api/UPDATE_FIELD', { key: 'token', value: user.token });
            this.commit('api/UPDATE_FIELD', { key: 'tokenIdleTimeout', value: user.tokenIdleTimeout });
            this.commit('api/UPDATE_FIELD', { key: 'websocketToken', value: user.websocketToken });
            this.commit('api/UPDATE_FIELD', { key: 'email', value: user.email });
            this.commit('api/UPDATE_FIELD', { key: 'workspaces', value: user.workspaces });
            this.commit('api/UPDATE_FIELD', { key: 'organizations', value: user.organizations });
            this.commit('api/UPDATE_FIELD', { key: 'currentWorkspace', value: user.currentWorkspace });
            this.commit('api/UPDATE_FIELD', { key: 'currentOrganization', value: user.currentOrganization });
            this.commit('api/UPDATE_FIELD', { key: 'name', value: user.name });
            this.commit('api/UPDATE_FIELD', { key: 'roles', value: user.roles });
            this.commit('api/UPDATE_FIELD', { key: '_id', value: user._id });
            this.commit('api/UPDATE_PERMISSIONS');

            this.$axios.setToken(user.token, 'Token');
          }

          if (routeAfterLogin) {
            this.commit('api/UPDATE_ROOT_FIELD', { key: 'forceDefaultRoute', value: routeAfterLogin });
          }

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },
  API_FORGOT_PASSWORD: function (context, payload) {
    return this.$axios
      .post('/api/v1/users/forgotPassword', { user: payload.user, locale: context.rootState.i18n.locale })
      .then(r => r.data)
      .then(
        result => {
          this.dispatch('api/API_SUCCESS', {
            type: 'info',
            message: 'an email has been sent on ' + payload.user.email
          });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },
  /**
 * check valid password code and get user informations
 */
  API_CHECK_PASSWORD_TOKEN: async function (context, { token }) {
    return this.$axios
      .get('/api/v1/users/checkPasswordToken/' + token)
      .then(r => r.data)
      .then(
        user => {
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'password token is valid' });
          return user;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },
  API_RESET_PASSWORD: function (context, payload) {
    return this.$axios
      .post('/api/v1/users/resetPassword', { user: payload.user, token: payload.token })
      .then(r => r.data)
      .then(
        json => {
          const { token } = json;
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'paswword reset for ' + payload.user.email });

          if (token) {
            return this.dispatch('api/API_LOAD_LOGGED_USER', { token });
          }

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);

          return false;
        }
      );
  },
  API_SESSION_ACTIVE: async function (context, isActive) {
    if (!context.state.user.token) {
      return;
    }

    return this.$axios
      .put('/api/v1/users/active', { isActive });
  },
  RELOAD_CURRENT_SESSION_USER: async function (context, payload) {
    const mztoken = this.$mzLocalStorage.getItem('mztoken'),
      mzrefreshtoken = this.$mzLocalStorage.getItem('mzrefreshtoken');

    if (mztoken && mzrefreshtoken) {
      this.commit('api/UPDATE_FIELD', { key: 'token', value: mztoken });
      this.commit('api/UPDATE_FIELD', { key: 'refreshToken', value: mzrefreshtoken });
      this.$axios.setToken(mztoken, 'Token');

      const token = await this.dispatch('api/API_CHECK');
      if (!token) {
        await this.dispatch('api/API_LOGOUT', { debugSaveFrom: 'RELOAD_CURRENT_SESSION_USER received invalid API CHECK' });
      } else {
        return token;
      }
    }

    return false;
  },
  API_CHECK: async function (context, payload) {
    // Check if refresh token is already in progress
    if (context.state.refreshTokenPromise !== null &&
      typeof context.state.refreshTokenPromise === 'object' &&
      typeof context.state.refreshTokenPromise.then === 'function') {
      console.log('API_CHECK detect is already in progress ', context.state.refreshTokenPromise);
      return context.state.refreshTokenPromise;
    }

    console.log('API_CHECK send refresh user token');

    let refreshTokenPromise = this.$axios
      .post('/api/v1/users/token',
        {
          refreshToken: context.state.user.refreshToken,
          userToken: context.state.user.token,
          userId: context.state.user._id
        })
      .then(r => r.data)
      .then(
        json => {
          this.commit('api/UPDATE_ROOT_FIELD', { key: 'refreshTokenPromise', value: null });

          let user = json.user;
          this.$mzLocalStorage.setItem('mztoken', user.token);

          if (user.refreshToken) {
            this.$mzLocalStorage.setItem('mzrefreshtoken', user.refreshToken);
            this.commit('api/UPDATE_FIELD', { key: 'refreshToken', value: user.refreshToken });
          }

          this.commit('api/UPDATE_FIELD', { key: 'token', value: user.token });
          this.commit('api/UPDATE_FIELD', { key: 'tokenIdleTimeout', value: user.tokenIdleTimeout });
          this.commit('api/UPDATE_FIELD', { key: 'websocketToken', value: user.websocketToken });
          this.commit('api/UPDATE_FIELD', { key: 'email', value: user.email });
          this.commit('api/UPDATE_FIELD', { key: 'workspaces', value: user.workspaces });
          this.commit('api/UPDATE_FIELD', { key: 'organizations', value: user.organizations });
          this.commit('api/UPDATE_FIELD', { key: 'currentWorkspace', value: user.currentWorkspace });
          this.commit('api/UPDATE_FIELD', { key: 'currentOrganization', value: user.currentOrganization });
          this.commit('api/UPDATE_FIELD', { key: 'name', value: user.name });
          this.commit('api/UPDATE_FIELD', { key: 'roles', value: user.roles });
          this.commit('api/UPDATE_FIELD', { key: '_id', value: user._id });
          this.commit('api/UPDATE_PERMISSIONS');

          this.$axios.setToken(user.token, 'Token');

          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'user is logged' });

          return user.token;
        },
        err => {
          this.commit('api/UPDATE_ROOT_FIELD', { key: 'refreshTokenPromise', value: null });

          this.commit('api/UPDATE_FIELD', { key: 'token', value: null });
          this.commit('api/UPDATE_FIELD', { key: 'tokenIdleTimeout', value: null });
          this.commit('api/UPDATE_FIELD', { key: 'websocketToken', value: null });

          if (err && err.response && err.response.status == 401) {
            this.commit('api/UPDATE_ROOT_FIELD', { key: 'previousConnectedEmail', value: context.state.user.email });
            this.commit('api/UPDATE_ROOT_FIELD', { key: 'forceDefaultRoute', value: this.$router.currentRoute.path });
          }

          console.log('API_CHECK error remove keep login and token', err);
          this.$mzLocalStorage.removeItem('mzkeeplogin');
          this.$mzLocalStorage.removeItem('mztoken');
          this.$mzLocalStorage.removeItem('mzrefreshtoken');

          this.$axios.setToken(false, 'Token');

          return false;
        }
      );

    this.commit('api/UPDATE_ROOT_FIELD', { key: 'refreshTokenPromise', value: refreshTokenPromise });
    return refreshTokenPromise;
  },
  API_LOGOUT: async function (context, payload) {
    return this.$axios
      .post('/api/v1/users/logout', { userId: context.state.user._id, debugSaveFrom: payload.debugSaveFrom })
      .then(r => r.data)
      .then(
        json => {
          this.dispatch('api/API_RESET_ALL_STATE_AND_TOKEN');

          if (payload && payload.nextLoginRoute) {
            this.commit('api/UPDATE_ROOT_FIELD', { key: 'forceDefaultRoute', value: payload.nextLoginRoute });
          }
          if (payload && payload.saveConnectedEmail && context.state.user.email) {
            this.commit('api/UPDATE_ROOT_FIELD', { key: 'previousConnectedEmail', value: context.state.user.email });
          }
          return true;
        },
        err => {
          this.dispatch('api/API_RESET_ALL_STATE_AND_TOKEN');

          if (payload && payload.nextLoginRoute) {
            this.commit('api/UPDATE_ROOT_FIELD', { key: 'forceDefaultRoute', value: payload.nextLoginRoute });
          }
          if (payload && payload.saveConnectedEmail && context.state.user.email) {
            this.commit('api/UPDATE_ROOT_FIELD', { key: 'previousConnectedEmail', value: context.state.user.email });
          }

          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },
  API_RESET_ALL_STATE_AND_TOKEN: async function (context) {
    console.log('API_RESET_ALL_STATE_AND_TOKEN remove keep login');

    this.$mzLocalStorage.removeItem('mzkeeplogin');
    this.$mzLocalStorage.removeItem('mztoken');
    this.$mzLocalStorage.removeItem('mzrefreshtoken');

    this.$axios.setToken(false, 'Token');

    // auto commit RESET_STATE for all vuex module
    const _excludeStoreToReset = ['i18n'];
    for (const _module in context.rootState) {
      if (_excludeStoreToReset.indexOf(_module) === -1) {
        this.commit(_module + '/RESET_STATE');
      }
    }
  },
  API_REPORT: async function (context, payload) {
    return this.$axios
      .post('/api/v1/users/report', payload)
      .then(r => r.data)
      .then(
        result => {
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },
  API_SEND_ERROR: async function (context, payload = {}) {
    if (payload && payload.error && payload.error.name && payload.error.message) {
      // get needed params of error (Type of Error not be parsed by JSON.stringify)
      payload.error = {
        name: payload.error.name,
        message: payload.error.message,
        mzData: payload.error.mzData,
        stack: payload.error.stack,
      };
    }
    // add current app and api version for user
    payload.appBuild = context.rootGetters['app/appBuild'];
    payload.apiBuild = context.rootGetters['app/apiBuild'];
    payload.userId = context.state.user._id;

    return this.$axios
      .post('/api/v1/users/sendError', payload)
      .then(r => r.data)
      .then(
        result => {
          return true;
        },
        err => {
          return false;
        }
      );
  },
  API_STATUS: async function () {
    return this.$axios
      .get('/health/status');
  },

  API_GET_STRIPE_PORTAL_LINK: async function (context) {
    return this.$axios
      .get('/api/v1/users/stripePortalLink/')
      .then(r => r.data)
      .then(
        portalSession => {
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'success get stripe portal link' });
          return portalSession;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

};

export default {
  namespaced: true,
  getters,
  mutations,
  state,
  actions
};
