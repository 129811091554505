import Vue from 'vue';
import * as Sentry from "@sentry/vue";


export default ({ app }) => {
  if (process.env.VUE_SENTRY_DSN_URL) {
    console.log('------------ START Sentry -----------------------')
    Sentry.init({
      Vue,
      dsn: process.env.VUE_SENTRY_DSN_URL,
      integrations: [
        Sentry.browserTracingIntegration({ router: app.router }),
        Sentry.replayIntegration(),
        Sentry.replayCanvasIntegration(),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for tracing.
      // We recommend adjusting this value in production
      // Learn more at
      // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
      //tracesSampleRate: 1.0,

      // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
      //tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      // Learn more at
      // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  }
}