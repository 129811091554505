import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import Utils from "~/common/utils/store";
/**
 * Organization store
 *
 * register methods in your components with import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
 *
 * pick only the method you need
 *
   methods :{
  ...mapActions({
      loadOrganization: "organization/LOAD_ORGANIZATION",
      loadOrganizations:"organization/LOAD_ORGANIZATIONS",
      countOrganizations:"organization/COUNT_ORGANIZATIONS",
      loadAllWorkspacesByOrganization:"organization/LOAD_ALL_WORKSPACES_BY_ORGANIZATION",
      saveOrganization: "organization/SAVE_ORGANIZATION",
      saveGroupOffer: "organization/SAVE_GROUPOFFER",
      updateOrganization: "organization/UPDATE_ORGANIZATION",
      deleteOrganization: "organization/DELETE_ORGANIZATION",
      addWorkspaceInOrganization: "organization/ADD_WORKSPACE_IN_ORGANIZATION",
      addUserInOrganization: "organization/ADD_USER_IN_ORGANIZATION",
      updateOrganizationUser: "organization/UPDATE_ORGANIZATION_USER",
      removeUserFromOrganization: "organization/REMOVE_USER_FROM_ORGANIZATION",
      loadAvailableTags: "organization/LOAD_AVAILABLE_TAGS",
      exportGroupOffers: "organization/EXPORT_GROUP_OFFERS",
    }),
    ...mapMutations({
      updateOrganizationField: "organization/UPDATE_FIELD",
      updateOrganizationRootField: "organization/UPDATE_ROOT_FIELD",
      updateOrganizationArray: "organization/UPDATE_ARRAY",
      swapOrganizationArray: "organization/SWAP_ARRAY",
      updateOrganizationRootArray: "organization/UPDATE_ROOT_ARRAY",
      setCurrentOrganization:"organization/SET_CURRENT_ORGANIZATION",
      setOrganizations:"organization/SET_ORGANIZATIONS",
      addOrganization:"organization/ADD_ORGANIZATION",
      updateOrganization:"organization/UPDATE_ORGANIZATION",
      removeOrganization:"organization/REMOVE_ORGANIZATION",
      resetOrganization:"organization/RESET_ORGANIZATION",
      resetStateOrganization:"organization/RESET_STATE",
      setOrganizationsCount:"organization/SET_ORGANIZATIONS_COUNT",
    }),
    },

      computed: {
    ...mapState("organization", ["organizations","organization","organizationsCount","workspacesByOrganizations","organizationTagIndex"]),
    ...mapGetters("organization", ["listOrganizationMarketType"]),
      }
 */
/**
 * return default organization object
 */
function initialState() {
  return {
    _id: null,
    marketType: '',
    collaborationType: 'nickname',
    name: '',
    admin: null,// references model Users
    status: 'active',
    contactEmail: '',
    adminComment: '',
    sessionStrategy: '',// values  in [secure, monthly, weekly, test]
    authentificationStrategy: '',// values  in [login_code, login_password, specific]
    ssoConfiguration: null,
    alternativeSsoConfiguration: null,
    users: [],
    workspaces: [],
    activeOffer: null,
    offers: [],
    recursiveActiveOffer: null,
    stripeCustomerId: '',
    tags: [],
    fromGroupOffer: false,
    lang: '',
    createdAt: null
  }
}


const getDefaultState = () => {
  return {
    organizations: [],
    organizationsCount: 0,
    workspacesByOrganizations: [],
    organization: initialState(),
    organizationTagIndex: []
  }
}

// initial state
const state = getDefaultState;

// getters are functions
const getters = {
  listOrganizationMarketType: state => {
    return [
      { value: 'B2C', label: 'B2C' },
      { value: 'EDU', label: 'Education' },
      { value: 'B2B', label: 'B2B' },
    ];
  },
};

// mutations
const mutations = {
  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {Object} organizations
   *
   * set collections data organizations
   */
  SET_ORGANIZATIONS: (state, organizations) => {
    state.organizations = organizations;
  },

  /**
 * @param {Object} state - https://vuex.vuejs.org/guide/state.html
 * @param {Object} payload - { count }
 *
 * set count data organizations
 */
  SET_ORGANIZATIONS_COUNT: (state, { count }) => {
    state.organizationsCount = count;
  },

  /**
  * @param {Object} state - https://vuex.vuejs.org/guide/state.html
  * @param {Object} organizations
  *
  * set collections data organizations
  */
  SET_WORKSPACES_BY_ORGANIZATION: (state, organizations) => {
    state.workspacesByOrganizations = organizations;
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * add organization object in collection from payload.organization parameter
   */
  ADD_ORGANIZATION: (state, payload) => {
    state.organizations.push(payload.organization);
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update organization object in collection from payload.organization parameter
   */
  UPDATE_ORGANIZATION: (state, payload) => {
    const _index = state.organizations.findIndex(organization => organization._id === payload.organization._id);
    if (_index >= 0) {
      Object.assign(state.organizations[_index], payload.organization);
    }

    if (state.organization !== null && state.organization._id === payload.organization._id) {
      Object.assign(state.organization, payload.organization);
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {String} organizationID
   *
   * remove organization object from collection with organizationID
   */
  REMOVE_ORGANIZATION: (state, organizationID) => {
    const _index = state.organizations.findIndex(organization => organization._id === organizationID);
    if (_index >= 0) {
      state.organizations.splice(_index, 1);
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {String} organizationID
   *
   * set current organization object from collection with organizationID
   */
  SET_CURRENT_ORGANIZATION: (state, organizationID) => {
    if (organizationID === null) {
      state.organization = Object.assign({}, initialState());
    }
    else {
      state.organization = Object.assign(
        {},
        state.organization,
        state.organizations.find(organization => organization._id == organizationID)
      );
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one field of state.organization
   */
  UPDATE_FIELD: (state, { key, value }) => {
    Utils.setProperty(key, state.organization, value);
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one field of state
   */
  UPDATE_ROOT_FIELD: (state, { key, value }) => {
    Utils.setProperty(key, state, value);
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one array field of state.organization
   */
  UPDATE_ARRAY: (state, payload) => {
    if (payload.delete === true) {
      const _index = (payload.cb) ? state.organization[payload.key].findIndex(payload.cb) : state.organization[payload.key].indexOf(payload.value);
      if (_index >= 0) {
        state.organization[payload.key].splice(_index, 1);
      }
    }
    else {
      state.organization[payload.key].push(payload.value);
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * swap two elements of state.organization
   */
  SWAP_ARRAY: (state, payload) => {
    let oldVal = state.organization[payload.key][payload.from];
    state.organization[payload.key][payload.from] = state.organization[payload.key][payload.to];
    Vue.set(state.organization[payload.key], payload.to, oldVal)
  },

  /**
 * @param {Object} state - https://vuex.vuejs.org/guide/state.html
 * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
 *
 * update one array field of state
 */
  UPDATE_ROOT_ARRAY: (state, payload) => {
    if (payload.delete === true) {
      const _index = (payload.cb) ? state[payload.key].findIndex(payload.cb) : state[payload.key].indexOf(payload.value);
      if (_index >= 0) {
        state[payload.key].splice(_index, 1);
      }
    }
    else {
      state[payload.key].push(payload.value);
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   *
   * reset state.organization
   */
  RESET_ORGANIZATION: (state) => {
    state.organization = Object.assign({}, initialState());
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   *
   * reset complete state to default value
   */
  RESET_STATE: state => {
    Object.assign(state, getDefaultState());
  }

};

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {
  /**
   * fetch collection of organizations
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {String} query - see more https://github.com/loris/api-query-params
   * @return Promise
   */
  LOAD_ORGANIZATIONS: async function (context, payload) {

    return this.$axios.get('/api/v1/organization/list?filter=' + JSON.stringify(payload.filter) + '&sort=' + payload.sort + '&limit=' + payload.limit + '&skip=' + payload.skip)
      .then(r => r.data)
      .then(
        organizations => {
          this.commit("organization/SET_ORGANIZATIONS", organizations);
          this.dispatch('api/API_SUCCESS', { type: 'info', 'message': 'ORGANIZATIONS are loaded' });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);

          return false;
        }
      );
  },

  /**
  * fetch number of organizations
  * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
  * @param {String} query - see more https://github.com/loris/api-query-params
  * @return Promise
  */
  COUNT_ORGANIZATIONS: async function (context, payload) {
    return this.$axios
      .get('/api/v1/organization/count?filter=' + JSON.stringify(payload.filter))
      .then(r => r.data)
      .then(
        count => {
          this.commit('organization/SET_ORGANIZATIONS_COUNT', { count });
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'COUNT ORGANIZATIONS are loaded' });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  /**
  * fetch collection of workspaces
  * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
  * @return Promise
  */
  LOAD_ALL_WORKSPACES_BY_ORGANIZATION: async function (context, payload) {

    if (context.state.workspacesByOrganizations && context.state.workspacesByOrganizations.length > 0) {
      console.log('WORKSPACES_BY_ORGANIZATION already in state');
      return Promise.resolve(true);
    }

    return this.$axios.get('/api/v1/organization/listAllWorkspaceByOrganization')
      .then(r => r.data)
      .then(
        organizations => {
          this.commit("organization/SET_WORKSPACES_BY_ORGANIZATION", organizations);
          this.dispatch('api/API_SUCCESS', { type: 'info', 'message': 'WORKSPACES BY ORGANIZATION are loaded' });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  /**
   * fetch current organization object
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {String} organizationID
   * @param {String} query -see populate
   * @return Promise
   */
  LOAD_ORGANIZATION: async function (context, payload) {

    return this.$axios.get("/api/v1/organization/" + Utils.normalizePayloadURI(payload, 'organizationId'))
      .then(r => r.data)
      .then(
        organization => {

          this.commit("organization/UPDATE_FIELD", { key: 'marketType', value: organization.marketType });
          this.commit("organization/UPDATE_FIELD", { key: 'collaborationType', value: organization.collaborationType });
          this.commit("organization/UPDATE_FIELD", { key: 'name', value: organization.name });
          this.commit("organization/UPDATE_FIELD", { key: 'admin', value: organization.admin });
          this.commit("organization/UPDATE_FIELD", { key: 'status', value: organization.status });
          this.commit("organization/UPDATE_FIELD", { key: 'contactEmail', value: organization.contactEmail });
          this.commit("organization/UPDATE_FIELD", { key: 'adminComment', value: organization.adminComment });
          this.commit("organization/UPDATE_FIELD", { key: 'sessionStrategy', value: organization.sessionStrategy });
          this.commit("organization/UPDATE_FIELD", { key: 'authentificationStrategy', value: organization.authentificationStrategy });
          this.commit("organization/UPDATE_FIELD", { key: 'ssoConfiguration', value: organization.ssoConfiguration });
          this.commit("organization/UPDATE_FIELD", { key: 'alternativeSsoConfiguration', value: organization.alternativeSsoConfiguration });
          this.commit("organization/UPDATE_FIELD", { key: 'users', value: organization.users });
          this.commit("organization/UPDATE_FIELD", { key: 'workspaces', value: organization.workspaces });
          this.commit("organization/UPDATE_FIELD", { key: 'activeOffer', value: organization.activeOffer });
          this.commit("organization/UPDATE_FIELD", { key: 'offers', value: organization.offers });
          this.commit("organization/UPDATE_FIELD", { key: 'recursiveActiveOffer', value: organization.recursiveActiveOffer });
          this.commit("organization/UPDATE_FIELD", { key: 'stripeCustomerId', value: organization.stripeCustomerId });
          this.commit("organization/UPDATE_FIELD", { key: 'tags', value: organization.tags });
          this.commit("organization/UPDATE_FIELD", { key: 'fromGroupOffer', value: organization.fromGroupOffer });
          this.commit("organization/UPDATE_FIELD", { key: 'lang', value: organization.lang });
          this.commit("organization/UPDATE_FIELD", { key: 'createdAt', value: organization.createdAt });
          this.commit("organization/UPDATE_FIELD", { key: '_id', value: organization._id });
          this.dispatch('api/API_SUCCESS', { type: 'info', 'message': 'organization is loaded' });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  /**
   * create current organization object
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {Object} payload
   * @return Promise
   */
  SAVE_ORGANIZATION: function (context, payload) {

    return this.$axios.post("/api/v1/organization/", context.state.organization)
      .then(r => r.data)
      .then(
        organization => {
          this.commit("organization/UPDATE_FIELD", { key: '_id', value: organization._id });

          this.commit("organization/UPDATE_FIELD", { key: 'marketType', value: organization.marketType });
          this.commit("organization/UPDATE_FIELD", { key: 'collaborationType', value: organization.collaborationType });
          this.commit("organization/UPDATE_FIELD", { key: 'name', value: organization.name });
          this.commit("organization/UPDATE_FIELD", { key: 'admin', value: organization.admin });
          this.commit("organization/UPDATE_FIELD", { key: 'status', value: organization.status });
          this.commit("organization/UPDATE_FIELD", { key: 'contactEmail', value: organization.contactEmail });
          this.commit("organization/UPDATE_FIELD", { key: 'adminComment', value: organization.adminComment });
          this.commit("organization/UPDATE_FIELD", { key: 'sessionStrategy', value: organization.sessionStrategy });
          this.commit("organization/UPDATE_FIELD", { key: 'authentificationStrategy', value: organization.authentificationStrategy });
          this.commit("organization/UPDATE_FIELD", { key: 'ssoConfiguration', value: organization.ssoConfiguration });
          this.commit("organization/UPDATE_FIELD", { key: 'alternativeSsoConfiguration', value: organization.alternativeSsoConfiguration });
          this.commit("organization/UPDATE_FIELD", { key: 'users', value: organization.users });
          this.commit("organization/UPDATE_FIELD", { key: 'workspaces', value: organization.workspaces });
          this.commit("organization/UPDATE_FIELD", { key: 'activeOffer', value: organization.activeOffer });
          this.commit("organization/UPDATE_FIELD", { key: 'offers', value: organization.offers });
          this.commit("organization/UPDATE_FIELD", { key: 'recursiveActiveOffer', value: organization.recursiveActiveOffer });
          this.commit("organization/UPDATE_FIELD", { key: 'stripeCustomerId', value: organization.stripeCustomerId });
          this.commit("organization/UPDATE_FIELD", { key: 'tags', value: organization.tags });
          this.commit("organization/UPDATE_FIELD", { key: 'fromGroupOffer', value: organization.fromGroupOffer });
          this.commit("organization/UPDATE_FIELD", { key: 'lang', value: organization.lang });
          this.commit("organization/UPDATE_FIELD", { key: 'createdAt', value: organization.createdAt });
          this.commit("organization/ADD_ORGANIZATION", { organization });

          this.dispatch('api/API_SUCCESS', { type: 'info', 'message': 'organization is saved', display: true });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  /**
  * create an organization from a Group Offer form
  * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
  * @param {Object} payload
  * @return Promise
  */
  SAVE_GROUPOFFER: function (context, payload) {

    return this.$axios.post("/api/v1/organization/groupOffer", payload)
      .then(r => r.data)
      .then(
        organization => {
          this.commit("organization/UPDATE_FIELD", { key: '_id', value: organization._id });

          this.commit("organization/UPDATE_FIELD", { key: 'marketType', value: organization.marketType });
          this.commit("organization/UPDATE_FIELD", { key: 'collaborationType', value: organization.collaborationType });
          this.commit("organization/UPDATE_FIELD", { key: 'name', value: organization.name });
          this.commit("organization/UPDATE_FIELD", { key: 'admin', value: organization.admin });
          this.commit("organization/UPDATE_FIELD", { key: 'status', value: organization.status });
          this.commit("organization/UPDATE_FIELD", { key: 'contactEmail', value: organization.contactEmail });
          this.commit("organization/UPDATE_FIELD", { key: 'adminComment', value: organization.adminComment });
          this.commit("organization/UPDATE_FIELD", { key: 'sessionStrategy', value: organization.sessionStrategy });
          this.commit("organization/UPDATE_FIELD", { key: 'authentificationStrategy', value: organization.authentificationStrategy });
          this.commit("organization/UPDATE_FIELD", { key: 'ssoConfiguration', value: organization.ssoConfiguration });
          this.commit("organization/UPDATE_FIELD", { key: 'alternativeSsoConfiguration', value: organization.alternativeSsoConfiguration });
          this.commit("organization/UPDATE_FIELD", { key: 'users', value: organization.users });
          this.commit("organization/UPDATE_FIELD", { key: 'workspaces', value: organization.workspaces });
          this.commit("organization/UPDATE_FIELD", { key: 'activeOffer', value: organization.activeOffer });
          this.commit("organization/UPDATE_FIELD", { key: 'offers', value: organization.offers });
          this.commit("organization/UPDATE_FIELD", { key: 'recursiveActiveOffer', value: organization.recursiveActiveOffer });
          this.commit("organization/UPDATE_FIELD", { key: 'stripeCustomerId', value: organization.stripeCustomerId });
          this.commit("organization/UPDATE_FIELD", { key: 'tags', value: organization.tags });
          this.commit("organization/UPDATE_FIELD", { key: 'fromGroupOffer', value: organization.fromGroupOffer });
          this.commit("organization/UPDATE_FIELD", { key: 'lang', value: organization.lang });
          this.commit("organization/UPDATE_FIELD", { key: 'createdAt', value: organization.createdAt });
          this.commit("organization/ADD_ORGANIZATION", { organization });

          this.dispatch('api/API_SUCCESS', { type: 'info', 'message': 'group offer is saved', display: true });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  /**
  * update a specific organization
  * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
  * @return Promise
  */
  UPDATE_ORGANIZATION: async function (context, payload) {
    return this.$axios
      .put('/api/v1/organization/' + payload.organizationId, payload)
      .then(r => r.data)
      .then(
        organization => {
          this.commit('organization/UPDATE_ORGANIZATION', { organization });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  /**
  * delete current organization object
  * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
  * @param {Object} payload
  * @return Promise
  */
  DELETE_ORGANIZATION: function (context, payload) {
    let id = payload.id || context.state.organization._id

    return this.$axios.delete("/api/v1/organization/" + id)
      .then(r => r.data)
      .then(
        organization => {
          this.commit("organization/REMOVE_ORGANIZATION", id);
          this.dispatch('api/API_SUCCESS', { type: 'info', 'message': 'organization has been deleted' });

          this.commit("organization/RESET_ORGANIZATION");
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  /**
  * add workspace in organization
  * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
  * @param {Object} payload
  * @return Promise
  */
  ADD_WORKSPACE_IN_ORGANIZATION: function (context, payload) {
    return this.$axios.post("/api/v1/organization/" + payload.organizationId + "/addWorkspace", payload)
      .then(r => r.data)
      .then(
        organization => {
          this.commit("organization/UPDATE_ORGANIZATION", { organization });
          this.dispatch('api/API_SUCCESS', { type: 'info', 'message': 'add workspace success' });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  /**
  * add user in organization
  * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
  * @param {Object} payload
  * @return Promise
  */
  ADD_USER_IN_ORGANIZATION: function (context, payload) {
    return this.$axios.post("/api/v1/organization/" + payload.organizationId + "/addUser", payload)
      .then(r => r.data)
      .then(
        organization => {
          this.commit("organization/UPDATE_ORGANIZATION", { organization });
          this.dispatch('api/API_SUCCESS', { type: 'info', 'message': 'add user success' });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  /**
  * remove user from organization
  * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
  * @param {Object} payload
  * @return Promise
  */
  REMOVE_USER_FROM_ORGANIZATION: async function (context, payload) {
    return this.$axios.put("/api/v1/organization/removeUser", payload)
      .then(r => r.data)
      .then(
        organization => {
          this.commit("organization/UPDATE_ORGANIZATION", { organization });
          this.dispatch('api/API_SUCCESS', { type: 'info', 'message': 'remove user success' });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  /**
 * update a specific organization user
 * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
 * @return Promise
 */
  UPDATE_ORGANIZATION_USER: async function (context, payload) {
    return this.$axios
      .put("/api/v1/organization/" + payload.organizationId + "/updateUser", payload)
      .then(r => r.data)
      .then(
        organization => {
          this.commit("organization/UPDATE_ORGANIZATION", { organization });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  /**
  * fetch all distinct tags present in subdocuments
  * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
  * @param {String} magazineId
  * @param {String query -see populate
  * @return Promise
  */
  LOAD_AVAILABLE_TAGS: async function (context, payload) {
    let queryArgs = payload && payload.filter ? '?filter=' + JSON.stringify(payload.filter) : '';

    return this.$axios
      .get('/api/v1/organization/allBy/tags' + queryArgs)
      .then(r => r.data)
      .then(
        tag => {
          this.commit('organization/UPDATE_ROOT_FIELD', {
            key: 'organizationTagIndex',
            value: tag
          });

          return true;
        },
        err => {
          console.log(err);
          return false;
        }
      );
  },

  /**
     * export group offers as csv by workspace with magazine count by range date
     * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
     * @param {String} query - see more https://github.com/loris/api-query-params
     * @return Promise
     */
  EXPORT_GROUP_OFFERS: async function (context, payload) {
    return this.$axios
      .get('/api/v1/organization/exportGroupOffers?filter=' + JSON.stringify(payload.filter) + '&sort=' + payload.sort + '&rangeDate=' + payload.rangeDate)
      .then(r => r.data)
      .then(
        data => {
          this.dispatch('api/API_SUCCESS', data);
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );;
  },


};

let store = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default store;