import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import Utils from '~/common/utils/store';

/**
 * Magazine store
 *
 * register methods in your components with import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
 *
 * pick only the method you need
 *
   methods :{
  ...mapActions({
      loadCollaboration: "collaboration/LOAD_COLLABORATION",
      loadPublicCollaboration: "collaboration/LOAD_PUBLIC_COLLABORATION",
      initCollaboration: "collaboration/INIT_COLLABORATION",
      updateCollaboration: "collaboration/UPDATE_COLLABORATION",
      inviteUserToCollaboration: "collaboration/INVITE_USER_TO_COLLABORATION",
      resendInviteUserToCollaboration: "collaboration/RESEND_INVITE_USER_TO_COLLABORATION",
      removeUserFromCollaboration: "collaboration/REMOVE_USER_FROM_COLLABORATION",
      updateUserFromCollaboration: "collaboration/UPDATE_USER_FROM_COLLABORATION",
      updatePagesFromCollaboration: "collaboration/UPDATE_PAGES_FROM_COLLABORATION",
      setPageViewFromCollaboration: "collaboration/SET_PAGE_VIEW_FROM_COLLABORATION",
      updateCollaborationSettings: "collaboration/UPDATE_COLLABORATION_SETTINGS",
      loadCollaborations: "collaboration/LOAD_COLLABORATIONS",
    }),
    ...mapMutations({
      setCollaborations:"collaboration/SET_COLLABORATIONS",
      setCollaboration:"collaboration/SET_COLLABORATION",
      updateCollaborationField:"collaboration/UPDATE_FIELD",
      resetStateCollaboration:"collaboration/RESET_STATE",
      SET_USER_COLLABORATION
    }),
    },

    computed: {
    ...mapState("collaboration", ["collaborations","collaboration","userCollaboration"]),
    ...mapGetters("collaboration", ["collaborationIsActive", "getInferiorRoles","activeCollaborationUserByPages","collaborationUsersAssignList"]),
      }
 */

const ROLES = [
  { name: 'redactor', priority: 4 },
  { name: 'deputy', priority: 2 },
  { name: 'chief', priority: 0 }
];

const getDefaultState = () => {
  return {
    collaborations: [],
    collaboration: null,
    userCollaboration: null,
  };
};

const state = getDefaultState;

const getters = {
  collaborationIsActive: state => state.collaboration !== null && state.collaboration._id !== null && state.collaboration._id !== undefined,
  getInferiorRoles: state => roleName => {
    const role = ROLES.find(r => r.name === roleName)
    if (!role) return []

    return ROLES.filter(r => r.priority > role.priority).map(r => r.name)
  },
  // generate an object with page id key and all needed data by current connected user
  activeCollaborationUserByPages(state, getters, rootState) {
    if (!state.collaboration || !rootState.magazine.magazine.pageOrder || rootState.magazine.magazine.pageOrder.length === 0) {
      return [];
    }

    const userCollaborationChief = state.collaboration.users.find(user => user.role === 'chief');

    // set activeCollaborationUserByPages for all magazine page
    return rootState.magazine.magazine.pageOrder.reduce((list, page) => {
      const pageCollaboration = state.collaboration.pagesCollaboration.find(pageCollab => page._id === pageCollab.page);

      let userCollaboration = null,
        userFromCollaboration = null;
      let displayedUser = null,
        displayedUserFrom = null;

      if (pageCollaboration) {
        userCollaboration = pageCollaboration.history.length >= 1 ? state.collaboration.users.find(user => user._id === pageCollaboration.history[0].user) : null;
        userFromCollaboration = pageCollaboration.history.length >= 2 ? state.collaboration.users.find(user => user._id === pageCollaboration.history[1].user) : null;
      }

      if (!userCollaboration) {
        // set affected page to chief if no collaboration data found
        userCollaboration = userCollaborationChief;
      }

      if (userCollaboration) {
        if (state.userCollaboration && userCollaboration._id !== state.userCollaboration._id) {
          // check if page user is child of current user
          let parent = userCollaboration,
            isChild = false;
          while (parent && parent.parent) {
            if (parent.parent === state.userCollaboration._id) {
              isChild = true;
              parent = null;
            } else {
              parent = state.collaboration.users.find(user => user._id === parent.parent);
            }
          }
          displayedUser = Object.assign({}, userCollaboration, { isChildOfCurrentUser: isChild });
        } else {
          displayedUser = Object.assign({}, userCollaboration, { isCurrentUser: true });
        }
      }

      if (userFromCollaboration) {
        if (state.userCollaboration && userFromCollaboration._id !== state.userCollaboration._id) {
          // check if page user is child of current user
          let parent = userFromCollaboration,
            isChild = false;
          while (parent && parent.parent) {
            if (parent.parent === state.userCollaboration._id) {
              isChild = true;
              parent = null;
            } else {
              parent = state.collaboration.users.find(user => user._id === parent.parent);
            }
          }
          displayedUserFrom = Object.assign({}, userFromCollaboration, { isChildOfCurrentUser: isChild });
        } else {
          displayedUserFrom = Object.assign({}, userFromCollaboration, { isCurrentUser: true });
        }
      }

      list[page._id] = {
        page: page._id,
        user: userCollaboration,
        displayedUser: displayedUser,
        displayedUserFrom: displayedUserFrom,
        isNewAssign: pageCollaboration ? pageCollaboration.isNewAssign : false
      };
      return list;
    }, {});
  },
  collaborationUsersByAccountId(state) {
    return state.collaboration ? state.collaboration.users.reduce((reducer, user) => {
      const userId = user.linkedAccount ? user.linkedAccount._id : null;
      if (userId) {
        reducer[userId] = user
      }
      return reducer;
    }, {}) : {}
  },
  collaborationUsersAssignList(state, getters, rootState, rootGetters) {
    // send list of user what current user can assign page
    if (!state.collaboration || !state.userCollaboration) {
      return [];
    }

    const canBypassParent = rootGetters['magazine/checkMagazinePerm']("magPerms:collaboration:assign:bypassParent");
    return state.collaboration.users.reduce((list, user) => {
      if (
        user._id === state.userCollaboration._id ||
        user.parent === state.userCollaboration._id ||
        user._id === state.userCollaboration.parent ||
        (canBypassParent/* && user.parent === state.userCollaboration.parent*/)
      ) {
        list.push(user);
      }
      return list;
    }, []);
  }
}

const mutations = {
  SET_COLLABORATIONS: function (state, collaborations) {
    state.collaborations = collaborations
  },

  SET_COLLABORATION: function (state, collaboration) {
    state.collaboration = collaboration;
  },
  UPDATE_FIELD: (state, { key, value }) => {
    if (!state.collaboration) {
      state.collaboration = {};
    }
    Utils.setProperty(key, state.collaboration, value);
  },

  SET_COLLABORATION_SETTINGS: function (state, settings) {
    //state.collaboration = collaboration;
    if (settings.associateSettings) {
      state.collaboration.settings.associateSettings = {
        ...state.collaboration.settings.associateSettings,
        ...settings.associateSettings
      };
    }
    if (settings.writerSettings) {
      state.collaboration.settings.writerSettings = {
        ...state.collaboration.settings.writerSettings,
        ...settings.writerSettings
      };
    }
  },

  SET_USER_COLLABORATION: function (state, user) {
    let userCollaboration;
    if (!state.collaboration || !user || !user._id) {
      userCollaboration = null;
    } else {
      userCollaboration = state.collaboration.users.find(userCollab => {
        return userCollab.linkedAccount && user._id === userCollab.linkedAccount._id;
      });
    }
    state.userCollaboration = userCollaboration;
  },

  RESET_STATE: state => {
    Object.assign(state, getDefaultState());
  },
}

const actions = {
  async LOAD_COLLABORATION(context, collaborationId) {
    if (!collaborationId) {
      context.commit('RESET_STATE');
    } else {
      const collaboration = await this.$axios.get(`/api/v1/collaboration/${collaborationId}`).then(response => response.data);
      context.commit('SET_COLLABORATION', collaboration);
      context.commit('SET_USER_COLLABORATION', context.rootState.api.user);
    }
  },
  async LOAD_PUBLIC_COLLABORATION(context, publicId) {
    const collaboration = await this.$axios
      .get(`/api/v1/collaboration/public/${publicId}`)
      .then(r => r.data)
      .catch(err => {
        this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
      });

    if (!collaboration) {
      return false;
    }

    context.commit('SET_COLLABORATION', collaboration);
    context.commit('SET_USER_COLLABORATION', null);
    return true;
  },
  async INIT_COLLABORATION(context, magazineId) {
    const collaboration = await this.$axios
      .post('/api/v1/collaboration', { magazine: magazineId })
      .then(r => r.data)
      .catch(err => {
        this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
      });

    if (!collaboration) {
      return false;
    }

    context.commit('SET_COLLABORATION', collaboration);
    context.commit('SET_USER_COLLABORATION', context.rootState.api.user);
    // update store magazine
    if (context.rootState.magazine.magazine._id === magazineId) {
      this.commit('magazine/UPDATE_FIELD', { key: 'collaboration', value: collaboration._id });
    }

    return true;
  },
  async UPDATE_COLLABORATION(context, { collaborationId, name }) {
    const collaboration = await this.$axios
      .put(`/api/v1/collaboration/${collaborationId}`, { name })
      .then(r => r.data)
      .catch(
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
        }
      );

    if (!collaboration) {
      return false;
    }

    this.commit('collaboration/SET_COLLABORATION', collaboration)
    return true;
  },
  async INVITE_USER_TO_COLLABORATION(context, { collaborationId, userId, userName, role, fromAdmin, sendInvit }) {
    const result = await this.$axios
      .post(`/api/v1/collaboration/${collaborationId}/invite`, { userId, userName, role, fromAdmin, sendInvit })
      .then(r => r.data)
      .catch(
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
        }
      );

    if (!result) {
      return false;
    }

    if (!fromAdmin && result.collaboration) {
      this.commit('collaboration/SET_COLLABORATION', result.collaboration)
    }

    return result.newCollabUser;
  },
  async RESEND_INVITE_USER_TO_COLLABORATION(context, { collaborationId, userId }) {
    return await this.$axios
      .post(`/api/v1/collaboration/${collaborationId}/resendInvit/${userId}`)
      .then(r => r.data)
      .catch(
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      ) !== false;
  },
  async REMOVE_USER_FROM_COLLABORATION(context, payload) {
    const collaboration = await this.$axios
      .put(`/api/v1/collaboration/removeUser`, payload)
      .then(r => r.data)
      .catch(
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
        }
      );

    if (!collaboration) {
      return false;
    }

    if (!payload.fromAdmin) {
      context.commit('SET_COLLABORATION', collaboration);
    }

    return true;
  },
  async UPDATE_USER_FROM_COLLABORATION(context, { collaborationId, user }) {
    const collaboration = await this.$axios
      .put(`/api/v1/collaboration/${collaborationId}/users/${user._id}`, {
        role: user.role,
        name: user.name,
        parent: user.parent
      })
      .then(r => r.data)
      .catch(
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
        }
      );

    if (!collaboration) {
      return false;
    }

    context.commit('SET_COLLABORATION', collaboration)
    return true;
  },
  async UPDATE_PAGES_FROM_COLLABORATION(context, { collaborationId, pages, user }) {
    const collaboration = await this.$axios
      .put(`/api/v1/collaboration/${collaborationId}/pages`, { pages, user })
      .then(r => r.data)
      .catch(
        async err => {
          if (err.response && err.response.data.canForce) {
            return await this._vm.$confirm(this.app.i18n.t('public.teams_list.force_assign_page.message', { name: err.response.data.currentOwner }), this.app.i18n.t('public.teams_list.force_assign_page.title'), {
              confirmButtonText: this.app.i18n.t('public.teams_list.force_assign_page.confirm'),
              cancelButtonText: this.app.i18n.t('public.teams_list.force_assign_page.cancel')
            }).then(async () => {
              return await this.$axios
                .put(`/api/v1/collaboration/${collaborationId}/pages`, { pages, user, force: true })
                .then(r => r.data)
                .catch(err => {
                  this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
                })
            }).catch(() => false);
          } else {
            this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          }
        }
      );

    if (!collaboration) {
      return false;
    }

    context.commit('SET_COLLABORATION', collaboration);
    return true;
  },
  async SET_PAGE_VIEW_FROM_COLLABORATION(context, { collaborationId, pageId }) {
    const collaboration = await this.$axios
      .put(`/api/v1/collaboration/${collaborationId}/page/${pageId}`, { isNewAssign: false })
      .then(r => r.data)
      .catch(
        async err => {
          // no need to dispatch error here
          //this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
        }
      );

    if (!collaboration) {
      return false;
    }

    context.commit('SET_COLLABORATION', collaboration);
    return true;
  },
  async UPDATE_COLLABORATION_SETTINGS(context, { collaborationId, settings }) {
    this.commit('collaboration/SET_COLLABORATION_SETTINGS', settings);

    const collaboration = await this.$axios
      .put(`/api/v1/collaboration/${collaborationId}/settings`, { settings })
      .then(r => r.data)
      .catch(
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
        }
      );

    if (!collaboration) {
      return false;
    }

    this.commit('collaboration/SET_COLLABORATION', collaboration)
    return true;
  },
  async LOAD_COLLABORATIONS(context, collaborations) {
    //TODO load collaborations from serveurs
    //context.commit('SET_COLLABORATIONS', collaborations)

  }
}

let store = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default store;
