import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import Utils from '~/common/utils/store';
import Validators from '~/utils/validators';

/**
 * Library store
 *
 * register methods in your components with import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
 *
 * pick only the method you need
 *
   methods :{
  ...mapActions({
      loadLibrary: "library/LOAD_LIBRARY",
      loadPublicTemplateLibrary: "library/LOAD_PUBLIC_TEMPLATE_LIBRARY",
      updateImageInfos: "library/UPDATE_IMAGE_INFOS",
      updateShapeInfos: "library/UPDATE_SHAPE_INFOS",
      httpUpload: "library/HTTP_UPLOAD",
      proceedUpload: "library/PROCEED_UPLOAD",
      deleteImage: "library/DELETE_IMAGE",
      deleteShape: "library/DELETE_SHAPE",
      loadCroppedImageByKey: "library/LOAD_CROPPED_IMAGE_BY_KEY",
      cleanLibrary: "library/CLEAN_LIBRARY",
    }),
    ...mapMutations({
      updateLibraryField: "library/UPDATE_FIELD",
      updateLibraryRootField: "library/UPDATE_ROOT_FIELD",
      updateLibraryArray: "library/UPDATE_ARRAY",
      swapLibraryArray: "library/SWAP_ARRAY",
      updateLibraryRootArray: "library/UPDATE_ROOT_ARRAY",
      resetLibrary:"library/RESET_LIBRARY",
      resetStateLibrary:"library/RESET_STATE",
      resetCroppedImage:"library/RESET_CROPPED_IMAGE",
      removeImage:"library/REMOVE_IMAGE",
      removeShape:"library/REMOVE_SHAPE",
      updateImage:"library/UPDATE_IMAGE",
      updateShape:"library/UPDATE_SHAPE"
    }),
    },

      computed: {
    ...mapState("library", ["library","publicTemplateLibrary","croppedImage"]),
    ...mapGetters("library", ["getExistingVariantGroups"]),
      }
 */
/**
 * return default library object
 */
function initialState() {
  return {
    _id: null,
    magazine: null, // references model Magazine
    images: [], // references model Image
    shapes: [] // references model Shape
  };
}

/**
 * return default image object
 */
function initialStateCroppedImage() {
  return {
    _id: null,
    key: null,
    source: null,
    page: null,
    rect: { top: 0, left: 0, width: 0, height: 0 },
    scale: 1.0
  };
}

const getDefaultState = () => {
  return {
    library: initialState(),
    hasLoadedFullLibrary: false,
    publicTemplateLibrary: initialState(),
    croppedImage: initialStateCroppedImage()
  };
};

// initial state
const state = getDefaultState;

// getters are functions
const getters = {

  getExistingVariantGroups: state => {
    return state.library.shapes.reduce((list, shape) => {
      if (shape.variantGroup && list.indexOf(shape.variantGroup) === -1) {
        list.push(shape.variantGroup);
      }
      return list;
    }, []);
  }
};
// mutations
const mutations = {

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one field of state.library
   */
  UPDATE_FIELD: (state, { key, value }) => {
    Utils.setProperty(key, state.library, value);
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one field of state
   */
  UPDATE_ROOT_FIELD: (state, { key, value }) => {
    Utils.setProperty(key, state, value);
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one array field of state.library
   */
  UPDATE_ARRAY: (state, payload) => {
    if (payload.delete === true) {
      const _index = payload.cb
        ? state.library[payload.key].findIndex(payload.cb)
        : state.library[payload.key].indexOf(payload.value);
      if (_index >= 0) {
        state.library[payload.key].splice(_index, 1);
      }
    } else {
      state.library[payload.key].push(payload.value);
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * swap two elements of state.library
   */
  SWAP_ARRAY: (state, payload) => {
    let oldVal = state.library[payload.key][payload.from];
    state.library[payload.key][payload.from] = state.library[payload.key][payload.to];
    Vue.set(state.library[payload.key], payload.to, oldVal);
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one array field of state
   */
  UPDATE_ROOT_ARRAY: (state, payload) => {
    if (payload.delete === true) {
      const _index = payload.cb ? state[payload.key].findIndex(payload.cb) : state[payload.key].indexOf(payload.value);
      if (_index >= 0) {
        state[payload.key].splice(_index, 1);
      }
    } else {
      state[payload.key].push(payload.value);
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   *
   * reset state.library
   */
  RESET_LIBRARY: state => {
    state.library = Object.assign({}, initialState());
    state.publicTemplateLibrary = Object.assign({}, initialState());
  },

  /**
  * @param {Object} state - https://vuex.vuejs.org/guide/state.html
  *
  * reset state.croppedImage
  */
  RESET_CROPPED_IMAGE: state => {
    state.croppedImage = Object.assign({}, initialStateCroppedImage());
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   *
   * reset complete state to default value
   */
  RESET_STATE: state => {
    Object.assign(state, getDefaultState());
  },

  REMOVE_IMAGE: (state, payload) => {
    let foundIndex = state.library.images.findIndex(e => e._id === payload._id);
    if (foundIndex != -1) {
      if (state.hasLoadedFullLibrary) {
        Vue.set(state.library.images[foundIndex], 'deleted', true);
      } else {
        state.library.images.splice(foundIndex, 1);
      }
    }
  },

  REMOVE_SHAPE: (state, payload) => {
    let foundIndex = state.library.shapes.findIndex(e => e._id === payload._id);
    if (foundIndex != -1) {
      if (state.hasLoadedFullLibrary) {
        Vue.set(state.library.shapes[foundIndex], 'deleted', true);
      } else {
        state.library.shapes.splice(foundIndex, 1);
      }
    }
  },

  UPDATE_IMAGE: (state, image) => {
    let index = state.library.images.findIndex(e => {
      return e._id === image._id;
    });
    state.library.images.splice(index, 1, image);
  },

  UPDATE_SHAPE: (state, shape) => {
    let index = state.library.shapes.findIndex(e => {
      return e._id === shape._id;
    });
    state.library.shapes.splice(index, 1, shape);
  }
};

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {

  /**
   * fetch current library object
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {String} libraryId
   * @param {Boolean} full
   * @return Promise
   */
  LOAD_LIBRARY: async function (context, { libraryId, full = false }) {

    return this.$axios
      .get('/api/v1/library/' + libraryId + (full ? "?full=1" : ""))
      .then(r => r.data)
      .then(
        library => {
          this.commit('library/UPDATE_ROOT_FIELD', { key: 'hasLoadedFullLibrary', value: full });

          this.commit('library/UPDATE_FIELD', { key: 'magazine', value: library.magazine });
          this.commit('library/UPDATE_FIELD', { key: 'images', value: library.images });
          this.commit('library/UPDATE_FIELD', { key: 'shapes', value: library.shapes });
          this.commit('library/UPDATE_FIELD', { key: '_id', value: library._id });
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'library is loaded' });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },


  /**
   * fetch current publicTemplateLibrary object
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {String} templateId
   * @return Promise
   */
  LOAD_PUBLIC_TEMPLATE_LIBRARY: async function (context, { templateId }) {
    // don't reload library if already in state
    if (templateId === context.state.publicTemplateLibrary.magazine) {
      console.log('LOAD_PUBLIC_TEMPLATE_LIBRARY already in state - ' + templateId);
      return Promise.resolve(true);
    }

    return this.$axios
      .get('/api/v1/library/public/' + templateId)
      .then(r => r.data)
      .then(
        library => {
          this.commit('library/UPDATE_ROOT_FIELD', { key: 'publicTemplateLibrary', value: library });
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'library is loaded' });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  /**
   * Update image informations Name and Credit, identified by imageid
   * @param {*} context
   * @param {*} obj {id: String, name: String, credit:  String }
   */
  UPDATE_IMAGE_INFOS: function (context, obj) {
    if (obj) {
      return this.$axios
        .put('/api/v1/image/infos/' + obj.id, obj)
        .then(r => r.data)
        .then(image => {
          this.commit('library/UPDATE_IMAGE', image);
          return true;
        })
        .catch((error) => {
          this.dispatch('api/API_ERROR', error.response.data);
          return false;
        });
    }
  },


  /**
   * Update image informations Name and Credit, identified by imageid
   * @param {*} context
   * @param {*} obj {id: String, name: String, credit:  String }
   */
  UPDATE_SHAPE_INFOS: function (context, obj) {
    if (obj) {
      return this.$axios
        .put('/api/v1/shape/infos/' + obj.id, obj)
        .then(r => r.data)
        .then(shape => {
          this.commit('library/UPDATE_SHAPE', shape);
          return true;
        })
        .catch((error) => {
          this.dispatch('api/API_ERROR', error.response.data);
          return false;
        });
    }
  },

  HTTP_UPLOAD: function (context, { url, allowedTypes, libraryType }) {
    const libraryId = context.state.library._id;
    if (!libraryId) {
      this.dispatch('api/API_ERROR', { tr: 'serverResponse.library.errorLibraryNotLoaded' });
      return false;
    }

    if (Validators.isUrl(url)) {
      let data = {
        libraryID: libraryId,
        imgUrl: url,
        allowedTypes,
        libraryType
      };

      return this.$axios
        .post('/api/v1/library/uploadFromUrl/' + libraryId, data)
        .then(r => r.data)
        .then(response => {
          if (libraryType === 'image') {
            // check if image exists
            let found = context.state.library.images.findIndex(e => {
              return e._id === response.resource._id;
            });
            // and update it
            if (found != -1) {
              this.commit('library/UPDATE_IMAGE', response.resource);
              this.dispatch('api/API_SUCCESS', { type: 'info', tr: 'serverResponse.library.existingImageUpdated', display: true });
            } else {
              //or add new image in list
              this.commit('library/UPDATE_ARRAY', { key: 'images', value: response.resource });
              this.dispatch('api/API_SUCCESS', { type: 'info', tr: 'serverResponse.library.imageUploaded', display: true });
            }
          } else if (libraryType === 'shape') {
            // check if shape exists
            let found = context.state.library.shapes.findIndex(e => {
              return e._id === response.resource._id;
            });
            // and update it
            if (found != -1) {
              this.commit('library/UPDATE_SHAPE', response.resource);
              this.dispatch('api/API_SUCCESS', { type: 'info', tr: 'serverResponse.library.existingShapeUpdated', display: true });
            } else {
              //or add new shape in list
              this.commit('library/UPDATE_ARRAY', { key: 'shapes', value: response.resource });
              this.dispatch('api/API_SUCCESS', { type: 'info', tr: 'serverResponse.library.shapeUploaded', display: true });
            }
          }
          return true;
        })
        .catch(error => {
          this.dispatch('api/API_ERROR', error.response.data);
          return false;
        });
    } else {
      this.dispatch('api/API_ERROR', { tr: 'serverResponse.library.invalidUrl' });
      return false;
    }
  },

  PROCEED_UPLOAD: function (context, { file, libraryType }) {
    const libraryId = context.state.library._id;
    if (!libraryId) {
      this.dispatch('api/API_ERROR', { tr: 'serverResponse.library.errorLibraryNotLoaded' });
      return false;
    }

    if (file) {
      let data = new FormData();
      data.append('libraryID', libraryId); //important, set text parameters before image binary
      data.append('libraryType', libraryType);
      data.append('image', file, file.name);

      return this.$axios
        .post('/api/v1/library/upload/' + libraryId, data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(r => r.data)
        .then(response => {
          if (libraryType === 'image') {
            // check if image exists
            let found = context.state.library.images.findIndex(e => {
              return e._id === response.resource._id;
            });
            // and update it
            if (found != -1) {
              this.commit('library/UPDATE_IMAGE', response.resource);
              this.dispatch('api/API_SUCCESS', { type: 'info', tr: 'serverResponse.library.existingImageUpdated', display: true });
            } else {
              //or add new image in list
              this.commit('library/UPDATE_ARRAY', { key: 'images', value: response.resource });
              this.dispatch('api/API_SUCCESS', { type: 'info', tr: 'serverResponse.library.imageUploaded', display: true });
            }
          } else if (libraryType === 'shape') {
            // check if shape exists
            let found = context.state.library.shapes.findIndex(e => {
              return e._id === response.resource._id;
            });
            // and update it
            if (found != -1) {
              this.commit('library/UPDATE_SHAPE', response.resource);
              this.dispatch('api/API_SUCCESS', { type: 'info', tr: 'serverResponse.library.existingShapeUpdated', display: true });
            } else {
              //or add new shape in list
              this.commit('library/UPDATE_ARRAY', { key: 'shapes', value: response.resource });
              this.dispatch('api/API_SUCCESS', { type: 'info', tr: 'serverResponse.library.shapeUploaded', display: true });
            }
          }
          return true;
        })
        .catch(error => {
          this.dispatch('api/API_ERROR', error.response.data);
          return false;
        });
    } else {
      return false;
    }
  },

  /**
   * set current image object to deleted true
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {Object} payload
   * @return Promise
   */
  DELETE_IMAGE: function (context, payload) {
    return this.$axios
      .put('/api/v1/image/softdelete/' + payload._id)
      .then(r => r.data)
      .then(response => {
        if (response.error) {
          console.log('error on delete image');
          this.dispatch('api/API_ERROR', response.message);
          return false;
        } else {
          this.commit('library/REMOVE_IMAGE', payload);
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'image has been deleted' });
          return true;
        }
      })
      .catch((error) => {
        this.dispatch('api/API_ERROR', error.message);
        return false;
      });
  },

  /**
 * set current shape object to deleted true
 * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
 * @param {Object} payload
 * @return Promise
 */
  DELETE_SHAPE: function (context, payload) {
    return this.$axios
      .put('/api/v1/shape/softdelete/' + payload._id)
      .then(r => r.data)
      .then(response => {
        if (response.error) {
          console.log('error on delete shape');
          this.dispatch('api/API_ERROR', response.message);
          return false;
        } else {
          this.commit('library/REMOVE_SHAPE', payload);
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'shape has been deleted' });
          return true;
        }
      })
      .catch((error) => {
        this.dispatch('api/API_ERROR', error.message);
        return false;
      });
  },

  /**
   * fetch cropped image object by key
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {String} key
   * @param {String} pageId
   * @param {String} query -see populate
   * @return Promise
   */
  LOAD_CROPPED_IMAGE_BY_KEY: async function (context, { key, pageId }) {
    // don't reload cropped image if already in state
    if (key === context.state.croppedImage.key && pageId === context.state.croppedImage.page) {
      console.log('LOAD_LIBRARY already in state - ' + key);
      return Promise.resolve(true);
    }

    return this.$axios
      .get('/api/v1/cropped/?key=' + key + '&pageId=' + pageId)
      .then(r => r.data)
      .then(
        croppedImage => {
          this.commit('library/UPDATE_ROOT_FIELD', { key: 'croppedImage', value: croppedImage });
          return true;
        },
        err => {
          return false;
        }
      );
  },

  /**
  * fetch current library object
  * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
  * @param {String} libraryId
  * @return Promise
  */
  CLEAN_LIBRARY: async function (context, libraryId) {

    return this.$axios
      .put('/api/v1/library/clean/' + libraryId)
      .then(r => r.data)
      .then(
        result => {
          // remove images ansd shapes from store if library is loaded in state
          if (libraryId === context.state.library._id) {
            if (result.deletedImageIds && result.deletedImageIds.length > 0) {
              for (const _id of result.deletedImageIds) {
                this.commit('library/UPDATE_ARRAY', {
                  delete: true,
                  key: 'images',
                  cb: img_ => img_._id === _id
                });
              }
            }
            if (result.deletedShapeIds && result.deletedShapeIds.length > 0) {
              for (const _id of result.deletedShapeIds) {
                this.commit('library/UPDATE_ARRAY', {
                  delete: true,
                  key: 'shapes',
                  cb: shape_ => shape_._id === _id
                });
              }
            }
          }
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'library is cleaned' });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },
};

let store = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default store;
