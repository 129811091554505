export default function ({ app, redirect, store, route }) {

  const lastSession = app.$mzLocalStorage.getItem('mzlastsession'),
    keepLogin = app.$mzLocalStorage.getItem('mzkeeplogin'),
    lastSessionIsOut = !lastSession || lastSession < new Date().getTime() - (5 * 60 * 1000); // defined session timeout after 5 minutes

  console.log('session check lastSession', lastSession ? new Date(parseInt(lastSession)) : null);
  console.log('session check lastSessionIsOut', lastSessionIsOut);
  console.log('session check keepLogin', keepLogin);

  if (keepLogin || !lastSessionIsOut) {
    /* restore token if present in local storage and user not set in state (caused if used cookies instead of default localStorage) */
    store.dispatch('api/RELOAD_CURRENT_SESSION_USER').then((token) => {
      if (token) {
        /* switch session is active process */
        store.dispatch("api/API_SESSION_ACTIVE", true);
      }
    });
  } else {
    // force logout if user logged
    let isLogged = store.getters['api/isLogged'];
    if (isLogged) {
      store.dispatch("api/API_LOGOUT", { debugSaveFrom: 'Session Check last session is out' });
    }
  }

  let sessionInterval = setInterval(() => {
    app.$mzLocalStorage.setItem('mzlastsession', new Date().getTime());

    let isLogged = store.getters['api/isLogged'];
    if (isLogged) {
      store.dispatch("api/API_SESSION_ACTIVE", true);
    }

  }, 60 * 1000); // set mzlastsession all 60 secondes


  window.addEventListener('beforeunload', (event) => {
    if (sessionInterval) {
      clearInterval(sessionInterval);
      sessionInterval = null;
    }
    store.dispatch("api/API_SESSION_ACTIVE", false);
  }, false);
}